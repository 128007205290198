<template>
	<div class="page container">
		<div class="page__info">
			<div class="page__info--title">
				Courses
			</div>
			<div class="page__info--subtitle">
				Win more games, improve your skills
			</div>
		</div>
		<div class="separator" />
		<div class="content">
			<div class="side filters">
				<v2-courses-filters
					:filters="filters"
					:search="searchString"
					@update-search="onSearch"
					@update-filter="onFilter"
				/>
			</div>
			<div class="main courses">
				<div class="courses__list" v-if="courses.length">
					<v2-course-card v-for="course in courses" :key="course.id" :course="course" />
				</div>
				<pagination
					v-bind="pagination"
					@paginate="onPaginate"
				/>
			</div>
		</div>
	</div>
</template>

<route>
	{
		"meta": {
			"isPublic": true
		}
	}
</route>

<script>
	import api                   from '@/services/api';
	import { experienceLevels }  from '@/consts';
	import { biddingSystems   }  from '@/consts';

	import V2CourseCard  from '@/components/v2/courses/V2CourseCard.vue';
	import V2CoursesFilters from '@/components/v2/browse-courses/V2CoursesFilters.vue';
	import Pagination    from '@/components/ui/views/Pagination';

	export default {
		metaInfo: {
			title: 'Find a course'
		},

		layout: 'v2default',

		components: {
			V2CourseCard,
			V2CoursesFilters,
			Pagination
		},

		data: () => ({
			courses:        [],
			focus:          [],
			keywords:       [],
			allKeywords:    [],
			teachers:       [],
			searchString:   '',
			filters:  [],
			pagination: {
				perPage:      20,
				currentPage:  1,
				totalPages:   0
			},
			totalItems:     0
		}),

		computed: {

			getBreadcrumbs () {
				return [
					{
						path: '/browse-courses',
						text: 'Find a course'
					}
				].filter(Boolean);
			},

			getUsersDefaultExperienceLevel () {
				if (!process.env.VUE_APP_ENABLE_DEFAULT_COURSE_FILTERS) {
					return undefined;
				}
				return this.$store.getters['auth/getUsersDefaultExperienceLevel'];
			},

			getUsersDefaultBiddingSystem () {
				if (!process.env.VUE_APP_ENABLE_DEFAULT_COURSE_FILTERS) {
					return undefined;
				}
				return this.$store.getters['auth/getUsersDefaultBiddingSystem'];
			},

			getFilters () {
				return [
					{
						text: 'Experience level',
						value: 'experienceLevel',
						type: 'experienceLevelRange',
						default: this.getUsersDefaultExperienceLevel !== undefined ? [
							this.getUsersDefaultExperienceLevel
						] : undefined,
						options: experienceLevels.slice(0, 8)
					},
					{
						text: 'Teacher',
						value: 'teacherName',
						options: this.teachers.map((teacher) => {
							return {
								text: teacher,
								value: teacher
							};
						})
					},
					{
						text: 'Bidding system',
						value: 'biddingSystems',
						default: this.getUsersDefaultBiddingSystem !== undefined ? [
							this.getUsersDefaultBiddingSystem
						] : undefined,
						options: biddingSystems.map((biddingSystem) => {
							return {
								text: biddingSystem.value,
								value: biddingSystem.value
							};
						})
					},
					{
						text: 'Focus',
						value: 'focus',
						options: this.focus.map((focus) => {
							return {
								text: focus.focus,
								value: focus.focus
							};
						})
					},
					{
						text: 'Keyword',
						value: 'keywords',
						options: [...new Map(this.getKeywords.map(item => [item.text, item])).values()]
					}
				];
			}
		},

		async created () {
			await this.setCourses();

      if (this.$route.query.teacherName) {
        const teacherFilter = this.filters?.find(({ title }) => title === 'Teacher');
        if (!teacherFilter) {
          return;
        }
        teacherFilter.selected = [this.$route.query.teacherName];

        await this.setCourses();
      }
		},

		methods: {
			onSearch (value) {
				this.searchString = value;
				this.pagination.currentPage = 1;
				this.setCourses();
			},

			onFilter () {
				this.pagination.currentPage = 1;
				this.setCourses();
			},

			onPaginate (value) {
				this.pagination.currentPage = value;
				this.setCourses();
			},

			async setUnauthenticatedCourses () {
				return await api.courses.getPublishedPublicCourses(this.getFilterData());
			},

			async setAuthenticatedCourses () {
				const data = this.getFilterData();
				return await api.courses.getPublishedCourses(data);
			},

			async setAuthenticatedOrUnauthenticatedCourses () {
				if (!this.$store.getters['auth/getIsAuthenticated']) {
					return await this.setUnauthenticatedCourses();
				}
				return await this.setAuthenticatedCourses();
			},

			async setCourses () {
				const response = await this.setAuthenticatedOrUnauthenticatedCourses();
				if (!response) {
					this.$store.commit('ui/showError');
					return false;
				}

				this.focus = response.meta.focusKeyword;
				this.allKeywords = response.meta.focusKeyword;
				this.keywords = this.setKeywords(response.meta.focusKeyword);
				this.teachers = [...new Set(response.meta.teacherName)];
				this.pagination.totalPages = response.meta.numPages;
				this.pagination.currentPage = response.meta.page;
				this.totalItems = response.meta.totalItems;
				this.courses = response.results;

				this.setFilters(response.meta.filter);

				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});

				this.$store.commit('ui/setLoadingIsHidden');
			},

			getSkillFocusList () {
				return [
					...this.focus.map((item) => ({ label: item.focus, id: item.focus }))
				];
			},

			getKeywords (focusKeywords) {
				const uniqueKeywords = [...new Set(
					focusKeywords.reduce((prev, newVal) => [...prev, ...newVal.keywords], [])
				)];

				return uniqueKeywords.map((item) => ({ label: item, id: item }));
			},

			setKeywords (focusKeywords) {
				return this.getKeywords(focusKeywords);
			},

			getKeywordsList () {
				const selectedFocus = this.filters?.find(({ title }) => title === 'Skill focus')?.selected || [];

        if (selectedFocus.length) {
          const filteredFocusKeywords = this.allKeywords.filter(({ focus }) => selectedFocus.includes(focus));

          return this.getKeywords(filteredFocusKeywords);
        }

				return this.keywords;
			},

			getTeacherList () {
				return this.teachers.map((teacher) => {
					return {
						label: teacher,
						id: teacher
					};
				});
			},

			setFilters (filter) {

				const getSelected = (filterTitle) => {
					const selectedValue = this.filters?.find(({ title }) => title === filterTitle)?.selected;

					return selectedValue || [];
				};

				this.filters = [
					{
						type: 'Checkbox',
						title: 'Level',
						values: [
							{ label: 'Level 1', id: 1 },
							{ label: 'Level 2', id: 2 },
							{ label: 'Level 3', id: 3 },
							{ label: 'Level 4', id: 4 },
							{ label: 'Level 5', id: 5 },
							{ label: 'Level 6', id: 6 },
							{ label: 'Level 7', id: 7 },
							{ label: 'Level 8', id: 8 }
						],
						selected: getSelected('Level')
					},
					{
						type: 'Checkbox',
						title: 'Skill focus',
						values: this.getSkillFocusList(),
						selected: getSelected('Skill focus')
					},
					{
						type: 'Checkbox',
						title: 'Bidding System',
						values: [
							{ label: 'Acol', id: 'ACOL' },
							{ label: 'SAYC', id: 'SAYC' },
							{ label: '2 over 1', id: '2-over-1' },
							{ label: 'Majeure Cinquieme', id: 'Majeurs Cinquieme' }
						],
						selected: getSelected('Bidding System')
					},
					{
						type: 'Dropdown',
						title: 'Keyword',
						values: this.getKeywordsList(),
						selected: getSelected('Keyword')
					},
					{
						type: 'Dropdown',
						title: 'Teacher',
						values: this.getTeacherList(),
						selected: getSelected('Teacher')
					},
					{
						type: 'Checkbox',
						title: 'Price',
						values: [
							{ label: 'Free', id: '1' },
							{ label: 'Paid', id: '2' }
						],
						selected: getSelected('Price')
					}
				];
			},

			getFilterData () {
        if (!this.filters.length) {
          return {
            include: 'metadata,focus_keyword,only_linked_keywords,teachers,teacher_name',
            page: this.pagination.currentPage,
            per: this.pagination.perPage,
            sortBy: 'min_experience_level',
            sortDir: 'ASC'
          };
        }

				const getFilterValue = (filterName) => {
					return this.filters.find(({ title }) => title === filterName).selected.filter((item) => item !== -1);
				};

				return {
					include: 'metadata,focus_keyword,only_linked_keywords,teachers,teacher_name',
					searchString: this.searchString,
					filters: {
						experienceLevel: getFilterValue('Level'),
						focus: getFilterValue('Skill focus'),
						biddingSystems: getFilterValue('Bidding System'),
						keywords: getFilterValue('Keyword'),
						teacher_name: getFilterValue('Teacher'),
						price: getFilterValue('Price')
					},
					page: this.pagination.currentPage,
					per: this.pagination.perPage,
					sortBy: 'min_experience_level',
					sortDir: 'ASC'

				};
			}
		}
	};

</script>

<style lang="scss" scoped>
.content {

  @media (max-width: 1279px) {
    display: flex;
    flex-direction: column;

    .side {
      width: 100%;
    }

    .main {
      width: 100%;
    }
  }

  .courses {
    &__list {
      display: grid;
      gap: 12px;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 36px;

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
      }

      .course-card {
        width: 100%
      }
    }

    &__list-small {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 12px;
    }

    .border-box {
      border-radius: 4px;
      border: 1px dashed $c-gray-d;
    }

    &__more {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 94px;
      padding: 20px;

      &--text {
        width: 180px;
        @include font(18px, $c-medium-navy, 400, 23px);

        &.mini {
          display: none
        }
      }

    }

    &__list-empty {
      display: flex;
      flex-direction: column;
      gap: 24px;
      height: 335px;
      max-width: 440px;
      width: 100%;
      padding: 12px;
      align-items: center;
      justify-content: center;
      margin-bottom: 36px;

      &--text {
        @include font(18px, $c-medium-navy, 400, 23px);
        text-align: center;
      }

    }
  }
}

</style>
